//import logo from "./logo.svg";
//import './App.css';
//import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Header, Card, Container } from "semantic-ui-react";

function App() {
  return (
    <div className="App" align="center">
      <Container text>
        <Header
          as="h1"
          align="center"
          style={{ marginTop: "1em", marginBottom: "2em" }}
        >
          Muttley Cru - a Purveyor of the finest apps
        </Header>
        <p />
        <p />
        <Card centered={true} href="https://kilnhelper.web.app">
          <img
            src={require("./assets/kilnhelper_feature_graphic.png")}
            alt="Kiln Helper"
          />
          <Card.Content>
            <Card.Header>An app for Fused Glass Artists</Card.Header>
          </Card.Content>
        </Card>
      </Container>
      <Container
        style={{
          padding: "5em 0em",
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "32px",
        }}
      >
        <div>(C) Muttley Cru Ltd 2024</div>
      </Container>
    </div>
  );
}

export default App;
